import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import img1 from '../assets/images/carousel-1.png'
import img2 from '../assets/images/carousel-2.png'
import img3 from '../assets/images/carousel-3.png'
import img4 from '../assets/images/carousel-4.png'
import img5 from '../assets/images/carousel-5.png'

export const CarouselComp = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 0 },
            items: 1
        },
    };
    const imgStyle = {
        width: '100%',
        maxWidth: '306px',
        height: 'auto'

    }

    return (
        <Carousel responsive={responsive} 
                showDots={true} 
                centerMode={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
            >
            <div>
                <img src={img1} style={imgStyle} alt='sekabet screenshot 1' />
            </div>
            <div>
                <img src={img2} style={imgStyle} alt='sekabet screenshot 2'/>
            </div>
            <div>
                <img src={img3} style={imgStyle} alt='sekabet screenshot 3'/>
            </div>
            <div>
                <img src={img4} style={imgStyle} alt='sekabet screenshot 4'/>
            </div>
            <div>
                <img src={img5} style={imgStyle} alt='sekabet screenshot 5'/>
            </div>
        </Carousel>
    )
}
