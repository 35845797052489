import React from 'react'
import logo from '../assets/images/logo-light.png'

export const Navbar = () => {

    const nav = {
        height : '68px',
        display : 'block',
        padding : '0px 5%'
    }

    const logoWrapper = {
        maxWidth : '120px',
        height: '100%',
        display: 'flex'
    }

    const img = {
        width: '100%',
        height: 'auto',
        margin: 'auto'
    }

  return (
    <nav className='bg-color-green' style={nav}>
        <div style={logoWrapper} >
            <img src={logo} style={img} alt='sekabet light logo'></img>
        </div>
    </nav>
  )
}
