import React from 'react'

export const Button = ({ title, type, icon, text, action }) => {
    const primary = {
        width: '100%',
        maxWidth: '184px',
        background: '#117989',
        fontSize: '24px',
        color: 'white',
        padding: '10px 15px',
        border: '1.5px solid #117989',
        borderRadius: '8px',
        margin: '5px'
    }

    const secondary = {
        width: '100%',
        maxWidth: '184px',
        background: 'white',
        fontSize: '24px',
        color: '#117989',
        padding: '10px 15px',
        border: '1.5px solid #117989',
        borderRadius: '8px',
        margin: '5px'
    }

    const iconSytle = {
        display: 'flex'
    }

    const textStyle = {
        padding: '0'
    }

    const style = type === 'primary' ? primary : secondary;

    const onClickHandler = () => {
        action()
    }
    return (
        <button style={style} onClick={onClickHandler} >
            <div className='flex h-full' >
                {icon &&
                    <div style={iconSytle} >
                        <img className='w-full m-auto' src={icon} alt={text}/>
                    </div>
                }
                <div className='row' >
                    {title &&
                        <span className='ws-nowrap f-md col-12 w-full' style={textStyle}>{title}</span>
                    }
                    {text &&
                        <span className='ws-nowrap f-md col-12 bold f-md w-full' style={textStyle}>{text}</span>
                    }
                </div>
            </div>
        </button>
    )
}
