import './App.css';
import { Navbar } from './components/Navbar';
import { Button } from './components/Button';
import { CarouselComp } from './components/Carousel'
import AddToHomescreen from 'react-add-to-homescreen';
//image and icons
import img1 from './assets/images/image-1.png'
import img2 from './assets/images/image-2.png'
import logoDark from './assets/images/logo-dark.png'
//icons
import appleIcon from './assets/icons/apple-logo.svg'
import huaweiIcon from './assets/icons/huawei-logo.svg'
import playstoreIcon from './assets/icons/playstore-logo.svg'
import windowsIcon from './assets/icons/windows-logo.svg'
import tickIcon from './assets/icons/tick.svg'
import apkFile from './assets/files/SekaBet.apk'

function App() {

  const imgEffect1 = {
    position: 'absolute',
    height: '80%',
    width: '100%',
    top: '10%',
    background: 'rgba(48, 172, 191, 0.53)',
    filter: 'blur(84.5px)'
  }

  const listStyle = {
    listStyle: 'none',
    padding: '0',
    lineHeight: '3rem',
    letterSpacing: '0'
  }

  const handleAddToHomescreenClick = () => {
    alert(`
      1. Paylaş Butonuna Tıklayınız
      2. Açılan menüde 'Ana Ekrana Ekle' sekmesine basınız`);
  };

  return (
    <div className="App">
      <Navbar />
      <div className='main' >
        <section className='bg-color-dark flex section-md'>
          <div className='row'>
            <div className='col-6 col-md-12' >
              <h1 className='f-xxxl normal t-left' ><span className='bold' >Seka<span className='color-yellow' >Bet</span></span> Her Zaman, Her Yerde Seninle!</h1>
              <h2 className='f-lg normal t-left' >SekaBet’in zengin bahis seçeneklerine dilediğin an, dilediğin yerden ulaş. Sınırsız bahisin keyfini çıkar!</h2>
            </div>
            <div className='col-6 col-nd-12 h-full flex' >
              <div className='relative z-0 m-auto' >
                <img className='relative z-20 w-full' src={img1} alt='sekabet banner 1'></img>
                <div className='z-10' style={imgEffect1}></div>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-color-white section-lg' >
          <h2 className='f-xxl color-green' >SekaBet’i İndir!</h2>
          <p className='f-lg color-dark' >Uygun platformu seçin ve <span className='bold' >SekaBet</span> uygulamasını güvenli bir şekilde indirin.</p>
          <div className='row' >
            <div className='col-4 col-md-12'>
              <Button title={'Hemen İndir'} type={'primary'} icon={appleIcon} text={'IOS'} action={handleAddToHomescreenClick}/>
            </div>
            {/* <div className='col-3 col-md-12'>
              <Button title={'Hemen İndir'} type={'primary'} icon={windowsIcon} text={'Windows'} />
            </div> */}
            <div className='col-4 col-md-12'>
              <a href={apkFile} download={"SekaBet.apk"} target='_blank'>
                <Button title={'Hemen İndir'} type={'primary'} icon={playstoreIcon} text={'Play Store'} />
              </a>
            </div>
            <div className='col-4 col-md-12'>
              <a href={apkFile} download={"SekaBet.apk"} target='_blank'>
                <Button title={'Hemen İndir'} type={'primary'} icon={huaweiIcon} text={'Huawei'} />
              </a>
            </div>
          </div>
        </section>
        <section className='section-lg bg-color-green'>
          <h2 className='f-xxl normal' ><span className='bold' >Seka<span className='color-yellow' >Bet</span></span> Uygulaması Nasıl İndirilir?</h2>
          <p className='f-lg' >Aşağıdaki adımları takip ederek SekaBet mobil uygulamasını kolayca yükleyin!</p>
          <div className='my-lg' >
            <CarouselComp />
          </div>
        </section>
        <section className='section-lg'>
          <div className='row flex-lg align-center' >
            <div className='col-6 col-md-12 h-full' >
              <div className='w-full' >
                <img src={img2} alt='sekabet banner 2' className='w-full' />
              </div>
            </div>
            <div className='col-6 col-md-12 color-green' >
              <h2 className='f-xxl normal t-left' >Kullanışlı, Güçlü Arayüz ve Çok Çeşitli Bahis Seçenekleri</h2>
              <h3 className='f-lg t-left fw-700' >Son derece sade ve kullanışlı arayüz ile tanışın! Canlı casino, slotlar, spor oyunları ve daha bir çoğu SekaBet’te seni bekliyor. </h3>
              <ul className='color-dark t-left' style={listStyle}>
                <li><span><img src={tickIcon} alt='tick' /></span> Yüzlerce mobil oyun</li>
                <li><span><img src={tickIcon} alt='tick' /></span> Binlerce spor müsabakası ve canlı bahis keyfi</li>
                <li><span><img src={tickIcon} alt='tick' /></span> Güçlü altyapı ve kullanışlı arayüz</li>
                <li><span><img src={tickIcon} alt='tick' /></span> Güvenli ve canlı casino oyunları</li>
              </ul>
              <div className='flex' >
                <div className='m-1' >
                  <Button title={'İndir'} type={'primary'} />
                </div>
                <div className='m-1' >
                  <Button title={'Hemen Başla'} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className='section-lg bg-color-light'>
          <div className='row' >
            <div className='col-3' >
              <div className='flex w-full' >
                <img src={logoDark} alt='sekabet dark logo'></img>
              </div>
              <div>
                <p className='color-dark f-xs t-left' >
                  SekaBet’te son derece yüksek oranlarda bahis alın, kullanışlı
                  ve güçlü arayüz ile hızlı bir şekilde kazanma fırsatını elde edin.
                  Onlarca spor dalı, ve binlerce bahis seçeneği ile sınırsız bahis keyfini
                  SekaBet’te yakalayın. Sürekli canlı karşılaşmalar ve yüksek
                  bahis oranlarıyla heyecanınıza heyecan katın!
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} title={'Uygulama Olarak Ekle'} />
    </div>
  );
}

export default App;
